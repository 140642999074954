import * as types from '../mutation-types'

export default {
    namespaced: true,
    state:{
        summary: [],
        transactions: [],
        underLimitMaterials: [],
        transactionsStatement: []
    },

    actions:{
        fetchAll({ commit }, { queryType }){
            return window.axios.get('/materialtransactions/transactionsquery', {
                params:{
                    queryType
                    /*
                        queryType = 0 => Supervisors
                        queryType = 1 => MedicalReps
                    */
                }
            })
            .then((response) => {
                commit(types.STORE_TRANSACTINOS, response.data);
                return response.data;
            })
        },

        fetchByFilter({ commit }, { queryType, employeeId, materialId, materialFormId, fromDate, ToDate, page, perPage }){
            return window.axios.get('/materialtransactions/transactionsquery', {
                params:{
                    queryType,
                    /*
                        queryType = 0 => Supervisors
                        queryType = 1 => MedicalReps
                    */
                   employeeId,
                   materialId,
                   materialFormId,
                   fromDate,
                   ToDate,
                   page,
                   perPage
                }
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers["x-pagination-total-count"])
                };
            })
        },

        fetchSelfSummary({ commit }){
            return window.axios.get('/materialtransactions/selftransactionssummary')
            .then((response) => {
                commit(types.STORE_SUMMARY, response.data)
            });
        },

        fetchMaterialReceiptsSummary({ commit }, { employeeId, fromDate, toDate }){
            return window.axios.get(`/materialtransactions/${employeeId}/MarketingTransactionsQuery`, {
                params: { fromDate, toDate }
            }).then((response) => {
                return response.data
            });
        },

        fetchRemaining({ commit }, { id }){
            return window.axios.get(`/materialtransactions/${id}/TransactionsSummary`).then((response) => {
                return response.data;
            });
        },

        restore({ commit }, { employeeId }){
            return window.axios.post('/materialtransactions/deliverBack', { employeeId });
        },

        create({ commit }, samples){
            return window.axios.post('/materialtransactions/deliver', samples);
        },

        update({ commit }, { id, newSampleData }){
            return window.axios.put(`/materialtransactions/${id}`, newSampleData);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/materialtransactions/${id}`);
        },

        singleTransaction ({ commit }, { materialId, accountId, out, inAmount, notes, state }) {
            return window.axios.post('/materialtransactions/singleTransaction', { 
                materialId, 
                accountId, 
                out, 
                in: inAmount, 
                notes,
                state
            }).then(({ data }) => {
                return data
            })
        },

        updateSingleTransaction ({ commit }, { id, materialId, accountId, out, inAmount, notes, date, sellPrice, state }) {
            return window.axios.put(`/materialtransactions/singleTransaction/${id}`, {
                id,
                materialId, 
                accountId, 
                out, 
                in: inAmount, 
                notes, 
                date, 
                sellPrice, 
                state
            }).then(({ data }) => {
                return data
            })
        },

        // fetchStatement({ commit }, { MaterialId, FromDate, ToDate, AccountId, PerPage, Page, WithVisitTransaction, type, Date, SelectInAmount, SelectOutAmount, SortByCreatedAt, IsConsumed, SellPriceFrom, SellPriceTo, State }) {
        //     return window.axios.get(`/materialtransactions/statement`, { 
        //         params: { MaterialId, FromDate, ToDate, AccountId, PerPage, Page, WithVisitTransaction, type, Date, SelectInAmount, SelectOutAmount, SortByCreatedAt, IsConsumed, SellPriceFrom, SellPriceTo, State }
        //     }).then((response) => {
        //         return {
        //             data: response.data,
        //             total: parseInt(response.headers['x-pagination-total-count'])
        //         }
        //     })
        // },

        fetchStatement({ commit }, { MaterialId, FromDate, ToDate, AccountId, PerPage, Page, WithVisitTransaction, type, Date, SelectInAmount, SelectOutAmount, entity, IsConsumed }) {
            var urlSearchParams = new URLSearchParams();
            var params = { MaterialId, FromDate, ToDate, AccountId, PerPage, Page, WithVisitTransaction, type, Date, SelectInAmount, SelectOutAmount, entity, IsConsumed };
            
            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) {
                    urlSearchParams.append(param, params[param]);
                } 
            })

            // regionIds.forEach(id => {
            //     urlSearchParams.append("regionIds", id);
            // })
            return window.axios.get('/materialtransactions/statement', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        fetchTransactionsSummary({ commit }, { id }) {
            return window.axios.get(`materialTransactions/${id}/TransactionsSummary`).then(({ data }) => {
                return data
            })
        },

        createDeliverAndReceive({ commit }, { materials }) {
            return window.axios.post('materialTransactions/DeliverAndReceive', materials).then(({ data }) => {
                return data
            })
        },

        //  fetch materials loaned
        fetchMaterialLoaned ({ commit }, { materialId, employeeIds, FromDate, ToDate, IsConsumed, EntityId }) {
            // return window.axios.get('plannedVisits/PlannedMaterials', { params: { materialIds, employeeIds, fromDate, toDate } }).then(({ data }) => {
            //     commit(types.STORE_MATERIALS_MEDICAL_REP, data)
            //     return data
            // })
            var urlSearchParams = new URLSearchParams();
            var params = { materialId, FromDate, ToDate, IsConsumed, EntityId }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
        
            employeeIds.forEach(id => {
                urlSearchParams.append('employeeIds', id)
            })

            return window.axios.get('MaterialTransactions/LoanedMaterials', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                }
            })
        },
        // get under limit from material
        fetchUnderLimitMaterial ({ commit }) {
            return window.axios.get('/MaterialTransactions/UnderLimit/Materials').then(({ data }) => {
                commit(types.STORE_UNDER_LIMIT_MATERIALS, data)
            })
        },

        fetchMaterialsDoctor ({ commit }, { accountId }) {
            return window.axios.get('/MaterialTransaction/Entities', { accountId }).then(({ data }) => {
                return data
            })
        },

        fetchTransactionsStatement({ commit }, { materialId, accountId, cityId }) {
            const endpoint = accountId ? `account/${accountId}` : 'filter';
            const params = accountId ? { materialId } : { cityId, materialId };

            return window.axios.get(`materialTransactions/statistics/${endpoint}`, { params })
                .then(({ data }) => {
                    commit(types.STORE_TRANSACTIONS_STATEMENT, data);
                    return data;
                })
        },
    },

    mutations:{
        [types.STORE_SUMMARY](state, summary){
            state.summary = summary;
        },

        [types.STORE_TRANSACTINOS](state, transactions){
            state.transactions = transactions;
        },

        [types.STORE_UNDER_LIMIT_MATERIALS] (state, underLimitMaterials) {
            state.underLimitMaterials = underLimitMaterials
        },

        [types.STORE_TRANSACTIONS_STATEMENT](state, transactionsStatement){
            state.transactionsStatement = transactionsStatement;
        },
    },

    getters:{
        getTransactionById: state => id => state.transactions.find(c => c.id === id),
    }
}